import loadable from '@loadable/component';

import type { CmsApiFormBuilderBlockData } from 'modules/cms/api/types/blocks/CmsApiFormBuilderBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsFormBuilderBlockMapping: CmsBlockMapping<CmsApiFormBuilderBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsFormBuilderBlock'), {
      resolveComponent: (module) => module.CmsFormBuilderBlock,
    }),
  };
