import type { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { configValue } from 'config/appConfigUtils';
import type { FilestackImageFit } from 'types/Filestack';

type Props = {
  data: ApiFilestackImageData;
  width: number | undefined;
  height: number | undefined;
  fit: FilestackImageFit;
  /**
   * TODO: invert `insecureSVG` to `secureSVG` to make sure we're always taking
   * this property into account
   */
  insecureSVG: boolean;
};

function getImageBaseUrl() {
  if (configValue('filestack', 'mock')) return '/lolz/image';
  if (configValue('filestack', 'proxy')) return `/images`;

  return 'https://cdn.filestackcontent.com';
}

export function imageUrl({ data, width, height, fit, insecureSVG }: Props) {
  const { handle, mimetype } = data;

  if (configValue('filestack', 'mock') === 'storybook') {
    return `/storybook-fake/${handle}`;
  }

  const baseUrl = getImageBaseUrl();

  /*
   * Converting SVGs to WebPs causes them to lose too much quality
   */
  if (mimetype?.includes('image/svg')) {
    const secure = insecureSVG ? 'false' : 'true';
    return `${baseUrl}/no_metadata/output=secure:${secure}/compress/cache=expiry:max/${handle}`;
  }

  const resize = [
    width && `w:${Math.round(width)}`,
    height && `h:${Math.round(height)}`,
  ]
    .filter(Boolean)
    .join(',');

  const defaultFit = width && height ? 'crop' : 'max';
  const resizeQuery = resize && `resize=${resize},fit:${fit || defaultFit}`;
  const qualityQuery = `quality=value:70`;
  const outputFormat = mimetype?.includes('image/gif')
    ? ''
    : 'output=format:webp';

  // The order in which these items are put together matters a lot to Filestack
  //
  // Transformations are processed in order and if we order these transformations
  // incorrectly we can end up with much larger files
  return [
    baseUrl,
    'no_metadata',
    outputFormat,
    resizeQuery,
    qualityQuery,
    'compress',
    'cache=expiry:max',
    handle,
  ]
    .filter(Boolean)
    .join('/');
}

export function imageSizeUrl({ handle }: { handle: string }) {
  if (configValue('filestack', 'mock')) return '/lolz/image-size';

  return `https://cdn.filestackcontent.com/imagesize/${handle}`;
}
