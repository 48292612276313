import type { CmsApiBlock } from 'modules/cms/api/types/CmsApiBlock';
import type { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';

type Props = {
  content: CmsApiPage;
};

export function getContentHasBodyHeader({ content }: Props) {
  const [firstBlock]: Array<CmsApiBlock | undefined> = content.contentBlocks;

  const firstBlockSkipsTitle =
    content.contentBlocks &&
    content.contentBlocks.length > 0 &&
    (['CTA_HERO', 'DONATE', 'SUPPORT_CONTACT_FORM'].includes(firstBlock.type) ||
      (firstBlock.type === 'SPLIT_CONTENT' &&
        firstBlock.data.appearance === 'FULL_WIDTH'));

  return !(firstBlockSkipsTitle || content.heroSearch || !content.showTitle);
}
