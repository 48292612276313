import loadable from '@loadable/component';

import type { CmsApiSignUpBlockData } from 'modules/cms/api/types/blocks/CmsApiSignUpBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsSignUpBlockMapping: CmsBlockMapping<CmsApiSignUpBlockData> = {
  invariant: [],
  width: CmsMappedBlockWidth.FULL,
  Component: loadable(() => import('./CmsSignUpBlock'), {
    resolveComponent: (module) => module.CmsSignUpBlock,
  }),
};
