import type { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import type { FilestackImageFit } from 'types/Filestack';

import { getFilestackImageSizes } from './getFilestackImageSizes';
import { getFilestackImageSrcSet } from './getFilestackImageSrcSet';

type Args = {
  breakpointIndex: number;
  breakpoints: number[];
  data: ApiFilestackImageData;
  originalSize: { width: number; height: number };
  fit: FilestackImageFit;
  maxWidth: number | undefined;
  aspectRatio: { width: number; height: number };
};

export function getFilestackSourceDataForBreakpoint({
  breakpointIndex,
  breakpoints,
  data,
  originalSize,
  fit,
  maxWidth,
  aspectRatio,
}: Args):
  | { media: string; src: string; srcSet: string; width: number }
  | undefined {
  const breakpointWidth = breakpoints[breakpointIndex] - 1;
  const maxWidthOrBreakpoint = Math.min(
    maxWidth || originalSize.width,
    breakpointWidth,
  );

  const previousBreakpointWidth =
    breakpointIndex > 0 ? breakpoints[breakpointIndex - 1] : 0;

  if (maxWidthOrBreakpoint <= previousBreakpointWidth) return undefined;

  const { size1x, size2x } = getFilestackImageSizes({
    originalSize,
    width: maxWidthOrBreakpoint,
    height: undefined,
    aspectRatio,
  });

  const { src, srcSet } = getFilestackImageSrcSet({
    data,
    size1x,
    size2x,
    fit,
  });

  return {
    src,
    srcSet,
    media: `(min-width: ${previousBreakpointWidth}px) and (max-width: ${breakpointWidth}px)`,
    width: breakpointWidth,
  };
}
