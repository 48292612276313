import loadable from '@loadable/component';

import type { CmsApiSplitContentBlockData } from 'modules/cms/api/types/blocks/CmsApiSplitContentBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsSplitContentBlockMapping: CmsBlockMapping<CmsApiSplitContentBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsSplitContentBlock'), {
      resolveComponent: (module) => module.CmsSplitContentBlock,
    }),
  };
