import loadable from '@loadable/component';

import type { CmsApiTwoTilesBlockData } from 'modules/cms/api/types/blocks/CmsApiTwoTilesBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsTwoTilesBlockMapping: CmsBlockMapping<CmsApiTwoTilesBlockData> =
  {
    invariant: [
      {
        prop: 'tileOneHeadline',
        msg: 'Tile One Heading required for Two Tiles',
      },
      {
        prop: 'tileTwoHeadline',
        msg: 'Tile Two Heading required for Two Tiles',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsTwoTilesBlock'), {
      resolveComponent: (module) => module.CmsTwoTilesBlock,
    }),
  };
