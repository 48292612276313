import type {
  CmsApiBlock,
  CmsApiBlockType,
} from 'modules/cms/api/types/CmsApiBlock';
import type { CmsMappedBlock } from 'modules/cms/mappers/CmsMappedBlock';

const blocksWithoutHeadingDivider: CmsApiBlockType[] = [
  'CTA_HERO',
  'EMAIL_CAMPAIGN_SIGNUP_SINGLE',
  'EMAIL_CAMPAIGN_SIGNUP_DOUBLE',
  'FORM_BUILDER',
  'TWO_TILES',
  'SPLIT_CONTENT',
  'FLASH_CARD_QUIZ',
  'PERSONA_QUIZ',
  'RECENT_SALARIES',
  'TILE_GRID',
  'SIGN_UP',
  'SCROLL_TRACKING',
];

export function canHaveHeadingDivider<TBlock extends CmsApiBlock>(
  mappedBlock: CmsMappedBlock<TBlock>,
) {
  return !blocksWithoutHeadingDivider.some(
    (blockType) => mappedBlock.type === blockType,
  );
}
