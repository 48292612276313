import loadable from '@loadable/component';

import type { CmsApiEmailCampaignSignupSingleBlockData } from 'modules/cms/api/types/blocks/CmsApiEmailCampaignSignupSingleBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsEmailCampaignSignupSingleBlockMapping: CmsBlockMapping<CmsApiEmailCampaignSignupSingleBlockData> =
  {
    invariant: [
      {
        prop: 'headline',
        msg: 'Heading required for Email Campaign Signup Single',
      },
      {
        prop: 'buttonText',
        msg: 'Button Text required for Email Campaign Signup Single',
      },
      {
        prop: 'iterableMessageTypeId',
        msg: 'Iterable Message Type ID required for Email Campaign Signup Single',
      },
      {
        prop: 'iterableListId',
        msg: 'Iterable List ID required for Email Campaign Signup Single',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsEmailCampaignSignupSingleBlock'), {
      resolveComponent: (module) => module.CmsEmailCampaignSignupSingleBlock,
    }),
  };
