import loadable from '@loadable/component';

import type { CmsApiFlashCardQuizBlockData } from 'modules/cms/api/types/blocks/CmsApiFlashCardQuizBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsFlashCardsQuizBlockMapping: CmsBlockMapping<CmsApiFlashCardQuizBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsFlashCardQuizBlock'), {
      resolveComponent: (module) => module.CmsFlashCardQuizBlock,
    }),
  };
