import type { CmsApiRecommendedJobsBlockData } from 'modules/cms/api/types/blocks/CmsApiRecommendedJobsBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

import { CmsRecommendedJobsBlockLoadable } from './CmsRecommendedJobsBlockLoadable';

export const CmsRecommendedJobsBlockMapping: CmsBlockMapping<CmsApiRecommendedJobsBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: CmsRecommendedJobsBlockLoadable,
  };
