import loadable from '@loadable/component';

import type { CmsApiTileGridBlockData } from 'modules/cms/api/types/blocks/CmsApiTileGridBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsTileGridBlockMapping: CmsBlockMapping<CmsApiTileGridBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsTileGridBlock'), {
      resolveComponent: (module) => module.CmsTileGridBlock,
    }),
  };
