import type { CmsApiBlockType } from 'modules/cms/api/types/CmsApiBlock';

export function getPrevRealBlock<T extends { type: CmsApiBlockType }>(
  blocks: T[],
  i: number,
): T | undefined {
  let block;
  let j = i;

  const setBlock = () => {
    j -= 1;
    block = blocks[j];
  };

  setBlock();

  // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
  while (block && block.type === 'SCROLL_TRACKING') {
    setBlock();
  }

  return block;
}
