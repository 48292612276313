import loadable from '@loadable/component';

import type { CmsApiScrollTrackingBlockData } from 'modules/cms/api/types/blocks/CmsApiScrollTrackingBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsScrollTrackingBlockMapping: CmsBlockMapping<CmsApiScrollTrackingBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.FULL,
    Component: loadable(() => import('./CmsScrollTrackingBlock'), {
      resolveComponent: (module) => module.CmsScrollTrackingBlock,
    }),
  };
