import loadable from '@loadable/component';

import type { CmsApiAccordionBlockData } from 'modules/cms/api/types/blocks/CmsApiAccordionBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsAccordionBlockMapping: CmsBlockMapping<CmsApiAccordionBlockData> =
  {
    invariant: [
      {
        prop: 'sections',
        msg: 'Sections required for accordion',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsAccordionBlock'), {
      resolveComponent: (module) => module.CmsAccordionBlock,
    }),
  };
