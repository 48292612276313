import loadable from '@loadable/component';

import type { CmsApiFreeCodeBlockData } from 'modules/cms/api/types/blocks/CmsApiFreeCodeBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsFreeCodeBlockMapping: CmsBlockMapping<CmsApiFreeCodeBlockData> =
  {
    invariant: [
      {
        prop: 'code',
        msg: 'Code required',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsFreeCodeBlock'), {
      resolveComponent: (module) => module.CmsFreeCodeBlock,
    }),
  };
