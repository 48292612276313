import type { CmsApiBlock } from 'modules/cms/api/types/CmsApiBlock';
import type { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { getContentFirstRealBlockIndex } from 'modules/cms/helpers/getContentFirstRealBlockIndex';
import { getContentOffsetAnchors } from 'modules/cms/helpers/getContentOffsetAnchors';
import { getContentWithBanner } from 'modules/cms/helpers/getContentWithBanner';
import { getPrevRealBlock } from 'modules/cms/helpers/getPrevRealBlock';

import type { CmsMappedBlock } from './CmsMappedBlock';
import { mapCmsBlock } from './mapCmsBlock';

type Props = {
  content: CmsApiPage;
};

export function mapCmsBlocksForBody({
  content,
}: Props): CmsMappedBlock<CmsApiBlock>[] {
  const firstRealBlockIndex = getContentFirstRealBlockIndex({ content });
  const offsetAnchors = getContentOffsetAnchors({ content });
  const withBanner = getContentWithBanner({ content });

  return content.contentBlocks
    .map((block, index) => {
      const prevBlock = getPrevRealBlock(content.contentBlocks, index);

      return mapCmsBlock({
        content,
        isBody: true,
        index,
        block,
        isPageHeader: !withBanner && firstRealBlockIndex === index,
        anchor:
          index > 0 && prevBlock && prevBlock.type === 'ANCHOR'
            ? prevBlock
            : undefined,
        offsetAnchors,
      });
    })
    .filter((mappedBlock) => mappedBlock.type !== 'ANCHOR');
}
