import type { CmsApiBlock } from 'modules/cms/api/types/CmsApiBlock';

type Args = {
  block: CmsApiBlock;
};

export function getCmsBlockMustNotHaveDivider({ block }: Args) {
  if (
    block.type === 'SPLIT_CONTENT' &&
    block.data.appearance === 'FULL_WIDTH'
  ) {
    return true;
  }

  if (block.type === 'THREE_STEP_CTA' && block.data.overlap) return true;

  return false;
}
