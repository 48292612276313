import { useRef } from 'react';

import { useElementReachedEffect } from 'hooks/viewport/useElementReachedEffect';

export type ScrollTriggerEventDetail = { triggerId: string };

// NOTE: For now we're firing a CustomEvent as it makes it easier to keep
// trigger positions decoupled from the layout instead of relying on passing
// callbacks. Alternatively we could create a stream (zustand store) to
// subscribe to.
export function ScrollTrigger({ triggerId }: { triggerId: string }) {
  const triggerRef = useRef<HTMLDivElement>(null);

  useElementReachedEffect({
    ref: triggerRef,
    onReach: () => {
      document.dispatchEvent(
        new CustomEvent<ScrollTriggerEventDetail>(
          'idealist:scroll-trigger-reached',
          { detail: { triggerId } },
        ),
      );
    },
  });

  return (
    <div
      ref={triggerRef}
      data-scroll-trigger-id={triggerId}
      data-qa-id={`scroll-trigger-${triggerId}`}
    />
  );
}
