import loadable from '@loadable/component';

import type { CmsApiRecentSalariesBlockData } from 'modules/cms/api/types/blocks/CmsApiRecentSalariesBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsRecentSalariesMapping: CmsBlockMapping<CmsApiRecentSalariesBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.FULL,
    Component: loadable(() => import('./CmsRecentSalariesBlock'), {
      resolveComponent: (module) => module.CmsRecentSalariesBlock,
    }),
  };
