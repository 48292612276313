import loadable from '@loadable/component';

import type { CmsApiRichTextBlockData } from 'modules/cms/api/types/blocks/CmsApiRichTextBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsRichTextBlockMapping: CmsBlockMapping<CmsApiRichTextBlockData> =
  {
    invariant: [
      {
        prop: 'html',
        msg: 'Html data required for rich text',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsRichTextBlock'), {
      resolveComponent: (module) => module.CmsRichTextBlock,
    }),
  };
