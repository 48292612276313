import type { ReactNode } from 'react';

import { Box } from 'components/Box/Box';
import { Divider } from 'components/Divider/Divider';
import type { CmsApiBlock } from 'modules/cms/api/types/CmsApiBlock';
import type { CmsApiLayout } from 'modules/cms/api/types/CmsApiLayout';
import { canHaveHeadingDivider } from 'modules/cms/helpers/canHaveHeadingDivider';
import { getHideFirstHeadingDivider } from 'modules/cms/helpers/getHideFirstHeadingDivider';
import { mustHaveDivider } from 'modules/cms/helpers/mustHaveDivider';
import { mustNotHaveDivider } from 'modules/cms/helpers/mustNotHaveDivider';
import type { CmsMappedBlock } from 'modules/cms/mappers/CmsMappedBlock';

import { CmsMappedBlockContainer } from './CmsMappedBlockContainer';

type Props = {
  children: ReactNode;
  mappedBlock: CmsMappedBlock<CmsApiBlock>;
  prevBlock: CmsMappedBlock<CmsApiBlock> | undefined;
  nextBlock: CmsMappedBlock<CmsApiBlock> | undefined;
  layout: CmsApiLayout;
  index: number;
  isLastBlock: boolean;
};

export function CmsBlockContainerWithDividers({
  children,
  mappedBlock,
  prevBlock,
  nextBlock,
  layout,
  index,
  isLastBlock,
}: Props) {
  const hideFirstHeadingDivider = getHideFirstHeadingDivider({
    index,
    block: mappedBlock,
    prevBlock,
  });

  const fullWidthLayout = layout === 'FULL_WIDTH';

  return (
    <CmsMappedBlockContainer
      mappedBlock={mappedBlock}
      prevBlockHasBackground={Boolean(prevBlock?.hasBackground)}
      index={index}
      id={`cms-block-${mappedBlock.id}`}
      key={mappedBlock.id}
      fullWidthLayout={fullWidthLayout}
      showHeadingDivider={fullWidthLayout && canHaveHeadingDivider(mappedBlock)}
      hideFirstHeadingDivider={hideFirstHeadingDivider}
    >
      {fullWidthLayout &&
        index > 0 &&
        mustHaveDivider(mappedBlock) &&
        prevBlock &&
        !mustNotHaveDivider(prevBlock) &&
        !mappedBlock.hasBackground && (
          <Box
            maxWidth={1200}
            mb={fullWidthLayout ? [64, null, null, 70] : [40, null, null, 60]}
            mx="auto"
          >
            <Divider marginBottom={0} marginTop={0} />
          </Box>
        )}

      {children}

      {fullWidthLayout &&
        !isLastBlock &&
        mustHaveDivider(mappedBlock) &&
        nextBlock &&
        !mustHaveDivider(nextBlock) &&
        !mustNotHaveDivider(nextBlock) &&
        ((!mappedBlock.hasBackground && !nextBlock.hasBackground) ||
          (mappedBlock.hasBackground && nextBlock.hasBackground)) && (
          <Box
            maxWidth={1200}
            mt={fullWidthLayout ? [64, null, null, 70] : [40, null, null, 60]}
            mx="auto"
          >
            <Divider marginBottom={0} marginTop={0} />
          </Box>
        )}
    </CmsMappedBlockContainer>
  );
}
