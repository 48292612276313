import loadable from '@loadable/component';

import type { CmsApiSupportContactFormBlockData } from 'modules/cms/api/types/blocks/CmsApiSupportContactFormBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsSupportContactFormBlockMapping: CmsBlockMapping<CmsApiSupportContactFormBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.FULL,
    Component: loadable(() => import('./CmsSupportContactFormBlock'), {
      resolveComponent: (module) => module.CmsSupportContactFormBlock,
    }),
  };
