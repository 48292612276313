import type { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';

type Props = {
  content: CmsApiPage;
};

export function getContentOffsetAnchors({ content }: Props): boolean {
  return content.contentBlocks.some(
    (block) => block.type === 'STICKY_HEADER_LINKS',
  );
}
