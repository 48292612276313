import loadable from '@loadable/component';

import type { CmsApiAnchorBlockData } from 'modules/cms/api/types/blocks/CmsApiAnchorBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsAnchorBlockMapping: CmsBlockMapping<CmsApiAnchorBlockData> = {
  invariant: [
    {
      prop: 'anchor',
      msg: 'Anchor required for anchor',
    },
  ],
  width: CmsMappedBlockWidth.NARROW,
  Component: loadable(() => import('./CmsAnchorBlock'), {
    resolveComponent: (module) => module.CmsAnchorBlock,
  }),
};
