import loadable from '@loadable/component';

import type { CmsApiDonateBlockData } from 'modules/cms/api/types/blocks/CmsApiDonateBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsDonateBlockMapping: CmsBlockMapping<CmsApiDonateBlockData> = {
  invariant: [],
  width: CmsMappedBlockWidth.FULL,
  Component: loadable(() => import('./CmsDonateBlock'), {
    resolveComponent: (module) => module.CmsDonateBlock,
  }),
};
