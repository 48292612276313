import loadable from '@loadable/component';

import type { CmsApiCategoryHighlightBlockData } from 'modules/cms/api/types/blocks/CmsApiCategoryHighlightBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CategoryHighlightBlockMapping: CmsBlockMapping<CmsApiCategoryHighlightBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsCategoryHighlightBlock'), {
      resolveComponent: (module) => module.CmsCategoryHighlightBlock,
    }),
  };
