import loadable from '@loadable/component';

import type { CmsApiCtaHeroBlockData } from 'modules/cms/api/types/blocks/CmsApiCtaHeroBlock';
import { CmsApiCtaHeroBlockLayout } from 'modules/cms/api/types/blocks/CmsApiCtaHeroBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsCtaHeroBlockMapping: CmsBlockMapping<CmsApiCtaHeroBlockData> = {
  invariant: [
    {
      prop: 'text',
      msg: 'Text data required for call to action',
    },
    {
      prop: 'image',
      msg: 'Image data required for call to action',
    },
  ],
  width: (data) => {
    if (data.layout === CmsApiCtaHeroBlockLayout.COMPACT)
      return CmsMappedBlockWidth.NARROW;

    return CmsMappedBlockWidth.FULL;
  },
  Component: loadable(() => import('./CmsCtaHeroBlock'), {
    resolveComponent: (module) => module.CmsCtaHeroBlock,
  }),
};
