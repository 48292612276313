import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { FeedbackModal } from 'components/FeedbackModal/FeedbackModal';
import { useUser } from 'contexts/UserEnvironmentContext';
import { useToastPersistence } from 'hooks/useToastPersistence';
import {
  YesNoFeedback,
  postWebsiteFeedback,
} from 'modules/cms/api/feedback/postWebsiteFeedback';
import type { ScrollTriggerEventDetail } from 'modules/cms/components/ScrollTrigger/ScrollTrigger';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { trackDisplayToast } from 'utils/analytics/track/trackToast';
import { dismissToast, showToast } from 'zustand-stores/toastsStore';

import { getPageTypeAndLocale } from './CmsHelpPageFeedback/getPageTypeAndLocale';

type CmsHelpPageFeedbackProps = { contentId: string; contentTitle: string };
const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;
export function CmsHelpPageFeedback({
  contentId,
  contentTitle,
}: CmsHelpPageFeedbackProps): JSX.Element | null {
  const { pathname } = useLocation();
  const isLoggedIn = Boolean(useUser().user);
  const { hasDismissedToast, persistDismissedToastId, clearExpired } =
    useToastPersistence('help-page');
  const [hasCheckedDates, setHasCheckedDates] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [isPositiveFeedback, setIsPositiveFeedback] = useState(false);

  useEffect(() => {
    setHasCheckedDates(true);
    clearExpired(ONE_MONTH, 'help-page-toast');
  }, [clearExpired, hasCheckedDates]);

  const handleFeedbackSubmit = async (comment: string) => {
    const { locale } = getPageTypeAndLocale(pathname);
    await postWebsiteFeedback({
      campaign: `support_${locale}`,
      objectType: 'CMS_PAGE',
      objectId: contentId,
      url: pathname,
      rating: isPositiveFeedback ? YesNoFeedback.Yes : YesNoFeedback.No,
      comment,
    });

    trackEvent('Submitted: Help Article Feedback', {
      response_type: isPositiveFeedback ? 'YES' : 'NO',
      name: contentTitle,
      current_page_url: window.location.href,
    });
  };

  const handleModalClose = (hasSubmittedFeedback: boolean) => {
    if (!hasSubmittedFeedback) {
      handleFeedbackSubmit('');
      trackEvent('Dismissed: Help Article Feedback', {
        response_type: isPositiveFeedback ? 'YES' : 'NO',
        name: contentTitle,
        current_page_url: window.location.href,
      });
    }
    setShowFeedbackModal(false);
  };

  const [hasReachedEndOfContent, setHasReachedEndOfContent] = useState(false);
  useEffect(() => {
    const callback = (event: Event) => {
      if (!(event instanceof CustomEvent)) return;
      const { triggerId } = event.detail as ScrollTriggerEventDetail;
      if (triggerId !== 'cms-content-end') return;
      setHasReachedEndOfContent(true);
    };

    document.addEventListener('idealist:scroll-trigger-reached', callback);

    return () => {
      document.removeEventListener('idealist:scroll-trigger-reached', callback);
    };
  }, []);

  const [hasInitialDelayPassed, setHasInitialDelayPassed] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasInitialDelayPassed(true);
    }, 15_000);
    return () => clearTimeout(timeout);
  }, []);

  const hasReachedEndOfPageOrTimeHasPassed =
    hasReachedEndOfContent || hasInitialDelayPassed;

  useEffect(() => {
    if (!isLoggedIn || !hasCheckedDates) return;
    const { isHelpPage } = getPageTypeAndLocale(pathname);
    const canDisplay =
      isHelpPage &&
      !hasDismissedToast('help-page-toast') &&
      hasReachedEndOfPageOrTimeHasPassed;
    if (!canDisplay) return;

    trackDisplayToast({
      type: 'help_article',
      name: contentTitle,
      current_page_url: window.location.href,
    });

    showToast({
      id: 'help-page-toast',
      headline: getText('Was this article helpful?'),
      variant: 'info',
      actions: [
        {
          id: 'feedback',
          onFeedback: (result: 'positive' | 'negative') => {
            const isPositive = result === 'positive';

            trackClicked('Action Toast', {
              type: 'help_article',
              name: contentTitle,
              current_page_url: window.location.href,
              button_copy: isPositive ? 'yes' : 'no',
            });

            setIsPositiveFeedback(isPositive);
            setShowFeedbackModal(true);

            trackEvent('Modal View: Help Article Feedback', {
              response_type: isPositive ? 'YES' : 'NO',
              name: contentTitle,
              current_page_url: window.location.href,
            });

            persistDismissedToastId('help-page-toast');
            dismissToast('help-page-toast', 'open-feedback-modal');
          },
          type: 'feedback',
          positiveLabel: 'Yes',
          negativeLabel: 'No',
        },
      ],
      dismissible: true,
      durationMs: 15_000,
      onDismiss: (reason) => {
        if (reason !== 'open-feedback-modal') {
          trackEvent('Dismissed: Action Toast', {
            type: 'help_article',
            name: contentTitle,
            current_page_url: window.location.href,
          });
        }
        persistDismissedToastId('help-page-toast');
      },
    });
  }, [
    pathname,
    isLoggedIn,
    contentId,
    hasDismissedToast,
    persistDismissedToastId,
    hasCheckedDates,
    contentTitle,
    hasReachedEndOfPageOrTimeHasPassed,
  ]);

  if (!showFeedbackModal) return null;

  return (
    <FeedbackModal
      isPositive={isPositiveFeedback}
      onSubmit={handleFeedbackSubmit}
      onClose={handleModalClose}
      titlePositive={getText(
        'What specifically did you like about the article?',
      )}
      titleNegative={getText('How could we have done better?')}
      analyticsTitle="Help Article Feedback"
      qaId="help-article-feedback-modal"
    />
  );
}
