import type { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { breakpointValues } from 'theme/theme';
import type { FilestackImageFit } from 'types/Filestack';
import { range } from 'utils/functional/array/range';

import { getFilestackSourceDataForBreakpoint } from './getFilestackSourceDataForBreakpoint';

const breakpoints = [
  420,
  breakpointValues.xs,
  breakpointValues.sm,
  breakpointValues.md,
  breakpointValues.lg,
  1600,
];

type Args = {
  data: ApiFilestackImageData;
  fit: FilestackImageFit;
  insecureSVG: boolean | undefined;
  originalSize: { width: number; height: number };
  aspectRatio: { width: number; height: number };
  maxWidth: number | undefined;
  mobileData: ApiFilestackImageData | undefined;
  mobileAspectRatio: { width: number; height: number } | undefined;
};

export function getFilestackImageSourcesData({
  data,
  fit,
  insecureSVG,
  originalSize,
  aspectRatio,
  maxWidth,
  mobileData,
  mobileAspectRatio,
}: Args): { media: string; src: string; srcSet: string; width: number }[] {
  const imageInfo = { data, originalSize, insecureSVG };

  const mobileImageInfo = mobileData?.width &&
    mobileData?.height && {
      data: mobileData,
      originalSize: { width: mobileData.width, height: mobileData.height },
      // SVGs should not be getting sources data, if the code is incorrect and
      // an SVG gets here, assume it's insecure
      insecureSVG: true,
    };

  return range(breakpoints.length)
    .map((breakpointIndex) => {
      const isMobileBreakpoint = breakpointIndex < 3;

      const resolvedInfo =
        isMobileBreakpoint && mobileImageInfo ? mobileImageInfo : imageInfo;

      return getFilestackSourceDataForBreakpoint({
        breakpointIndex,
        breakpoints,
        data: resolvedInfo.data,
        originalSize: resolvedInfo.originalSize,
        maxWidth,
        aspectRatio: (isMobileBreakpoint && mobileAspectRatio) || aspectRatio,
        fit,
      });
    })
    .filter(Boolean);
}
