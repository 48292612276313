import loadable from '@loadable/component';

import type { CmsApiImageBlockData } from 'modules/cms/api/types/blocks/CmsApiImageBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsImageBlockMapping: CmsBlockMapping<CmsApiImageBlockData> = {
  invariant: [
    {
      prop: 'image',
      msg: 'Image data required for image',
    },
    {
      prop: 'width',
      msg: 'width required for image',
    },
  ],
  width: CmsMappedBlockWidth.NARROW,
  Component: loadable(() => import('./CmsImageBlock'), {
    resolveComponent: (module) => module.CmsImageBlock,
  }),
};
