import loadable from '@loadable/component';

import type { CmsApiVideoBlockData } from 'modules/cms/api/types/blocks/CmsApiVideoBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsVideoBlockMapping: CmsBlockMapping<CmsApiVideoBlockData> = {
  invariant: [
    {
      prop: 'videoUrl',
      msg: 'URL required for video',
    },
    {
      prop: 'width',
      msg: 'width required for video',
    },
  ],
  width: CmsMappedBlockWidth.NARROW,
  Component: loadable(() => import('./CmsVideoBlock'), {
    resolveComponent: (module) => module.CmsVideoBlock,
  }),
};
