import loadable from '@loadable/component';

import type { CmsApiImageGalleryBlockData } from 'modules/cms/api/types/blocks/CmsApiImageGalleryBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsImageGalleryBlockMapping: CmsBlockMapping<CmsApiImageGalleryBlockData> =
  {
    invariant: [
      {
        prop: 'images',
        msg: 'Images required for Image Gallery',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsImageGalleryBlock'), {
      resolveComponent: (module) => module.CmsImageGalleryBlock,
    }),
  };
