import loadable from '@loadable/component';

import type { CmsApiGradFairGradProgramLinksBlockData } from 'modules/cms/api/types/blocks/CmsApiGradFairGradProgramLinksBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsGradFairGradProgramLinksBlockMapping: CmsBlockMapping<CmsApiGradFairGradProgramLinksBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsGradFairGradProgramLinksBlock'), {
      resolveComponent: (module) => module.CmsGradFairGradProgramLinksBlock,
    }),
  };
