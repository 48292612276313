import type { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';

export type CmsApiCtaHeroType = 'CTA_HERO';

export enum CmsApiCtaHeroBlockLayout {
  COMPACT = 'COMPACT',
  STANDARD = 'STANDARD',
}

export enum CmsApiCtaHeroBlockTextColor {
  WHITE = 'WHITE',
  DARK_CONTENT_GREY = 'DARK_CONTENT_GREY',
}

export type CmsApiCtaHeroBlockData = {
  image: ApiFilestackImageData;
  withOverlay: boolean;
  /**
   * HTML
   */
  text: string;
  layout: CmsApiCtaHeroBlockLayout;
  bodyText?: string;
  textColor: CmsApiCtaHeroBlockTextColor;
  buttonText: string | null;
  buttonUrl: string | null;
};

export type CmsApiCtaHeroBlock = {
  id: string;
  type: CmsApiCtaHeroType;
  data: CmsApiCtaHeroBlockData;
};
