import loadable from '@loadable/component';

import type { CmsApiThreeStepCtaBlockData } from 'modules/cms/api/types/blocks/CmsApiThreeStepCtaBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsThreeStepCtaBlockMapping: CmsBlockMapping<CmsApiThreeStepCtaBlockData> =
  {
    invariant: [
      {
        prop: 'mainHeadline',
        msg: 'Main Heading required for Three Step Call-to-Action',
      },
    ],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsThreeStepCtaBlock'), {
      resolveComponent: (module) => module.CmsThreeStepCtaBlock,
    }),
  };
