import { useEffect } from 'react';

import type { ScrollTriggerEventDetail } from 'modules/cms/components/ScrollTrigger/ScrollTrigger';
import { trackEvent } from 'utils/analytics/track/trackEvent';

// NOTE: we're relying on events here so we can decouple the component holding
// the scroll trigger from the component firing the event.
// Alternatively, we could create a store to track a stream of scroll events
// instead of firing CustomEvents.
export function useTrackScrollTrigger(
  triggerIdToTrack: string,
  eventName: string,
) {
  useEffect(() => {
    const callback = (event: Event) => {
      if (!(event instanceof CustomEvent)) return;
      const { triggerId } = event.detail as ScrollTriggerEventDetail;
      if (triggerId !== triggerIdToTrack) return;
      trackEvent(`Scroll: ${eventName}`);
    };

    // NOTE: {once: true} is not needed as useElementReachedEffect
    // already fires only once.
    document.addEventListener('idealist:scroll-trigger-reached', callback);

    return () => {
      document.removeEventListener('idealist:scroll-trigger-reached', callback);
    };
  }, [eventName, triggerIdToTrack]);
}
