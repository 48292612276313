type PageTypeAndLocale =
  | {
      isHelpPage: true;
      locale: UserLocale;
    }
  | { isHelpPage: false; locale: null };

const denyListedPaths = new Set(['/en/help/', '/es/ayuda/', '/pt/ajuda/']);

export function getPageTypeAndLocale(pathname: string): PageTypeAndLocale {
  const pathnameWithSlash = `${pathname}/`;
  if (denyListedPaths.has(pathname) || denyListedPaths.has(pathnameWithSlash)) {
    return {
      isHelpPage: false,
      locale: null,
    };
  }

  if (pathname.startsWith('/en/help')) {
    return {
      isHelpPage: true,
      locale: 'en',
    };
  }

  if (pathname.startsWith('/es/ayuda')) {
    return {
      isHelpPage: true,
      locale: 'es',
    };
  }

  if (pathname.startsWith('/pt/ajuda')) {
    return {
      isHelpPage: true,
      locale: 'pt',
    };
  }

  return { isHelpPage: false, locale: null };
}
