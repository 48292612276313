import type { CmsApiBlock } from 'modules/cms/api/types/CmsApiBlock';
import type { CmsMappedBlock } from 'modules/cms/mappers/CmsMappedBlock';

import { mustHaveDivider } from './mustHaveDivider';
import { mustNotHaveDivider } from './mustNotHaveDivider';

type Args<TBlock extends CmsApiBlock> = {
  index: number;
  block: CmsMappedBlock<TBlock>;
  prevBlock: CmsMappedBlock<CmsApiBlock> | undefined;
};

export function getHideFirstHeadingDivider<TBlock extends CmsApiBlock>({
  index,
  block,
  prevBlock,
}: Args<TBlock>): boolean {
  if (index === 0) return true;

  if (!prevBlock) return false;

  return Boolean(
    mustHaveDivider(prevBlock) ||
      mustHaveDivider(block) ||
      mustNotHaveDivider(prevBlock) ||
      prevBlock.hasBackground,
  );
}
