import loadable from '@loadable/component';

import type { CmsApiFourArticlesHighlightBlockData } from 'modules/cms/api/types/blocks/CmsApiFourArticlesHighlightBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsFourArticlesHighlightBlockMapping: CmsBlockMapping<CmsApiFourArticlesHighlightBlockData> =
  {
    invariant: [],
    width: CmsMappedBlockWidth.NARROW,
    Component: loadable(() => import('./CmsFourArticlesHighlightBlock'), {
      resolveComponent: (module) => module.CmsFourArticlesHighlightBlock,
    }),
  };
