import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { scrollToHash } from 'utils/scrollIntoView';
import { getParam } from 'utils/url/getParam';
import { hasParam } from 'utils/url/hasParam';

export function useCmsContentPageScrollHandler() {
  const location = useLocation();

  useEffect(() => {
    const onClickContent = (e: MouseEvent) => {
      const el = e.target as HTMLElement;
      const href = el.getAttribute('href');

      // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      if (el.nodeName === 'A' && /^#/.test(href)) {
        e.preventDefault();
        // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        scrollToHash(href);
      }
    };

    document.addEventListener('click', onClickContent);
    return () => {
      document.removeEventListener('click', onClickContent);
    };
  }, []);

  useEffect(() => {
    if (hasParam(location.search, 'block')) {
      const el = document.getElementById(
        // TODO: Fix this from eslint v9 update
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `cms-block-${getParam(location, 'block')}`,
      );

      if (el) {
        window.scrollBy(0, el.getBoundingClientRect().top - 200);
      }
    }
  }, [location]);

  useEffect(() => {
    if (window.location.hash) {
      window.setTimeout(() => {
        scrollToHash(window.location.hash.split('?')[0], {
          padding: 32,
          noTransition: true,
        });
      }, 1);
    }
  }, []);
}
