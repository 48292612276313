import type { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import type { FilestackImageFit } from 'types/Filestack';
import { imageUrl } from 'utils/filestack';

type Args = {
  data: ApiFilestackImageData;
  size1x: { width: number; height: number };
  size2x: { width: number; height: number } | undefined;
  fit: FilestackImageFit;
};

export function getFilestackImageSrcSet({ data, size1x, size2x, fit }: Args) {
  const getSrc = (size: { width: number; height: number }) =>
    imageUrl({
      data,
      width: size.width,
      height: size.height,
      fit,
      // SVGs should not have srcSet
      insecureSVG: true,
    });

  const src1x = getSrc(size1x);
  const src2x = size2x && getSrc(size2x);

  return { src: src1x, srcSet: src2x ? `${src1x} 1x, ${src2x} 2x` : src1x };
}
