import loadable from '@loadable/component';

import type { CmsApiStickyHeaderLinksBlockData } from 'modules/cms/api/types/blocks/CmsApiStickyHeaderLinksBlock';
import type { CmsBlockMapping } from 'modules/cms/blocks/CmsBlockMapping';
import { CmsMappedBlockWidth } from 'modules/cms/mappers/CmsMappedBlockWidth';

export const CmsStickyHeaderLinksBlockMapping: CmsBlockMapping<CmsApiStickyHeaderLinksBlockData> =
  {
    invariant: [
      {
        prop: 'links',
        msg: 'Links required for sticky header links',
      },
    ],
    width: CmsMappedBlockWidth.FULL,
    Component: loadable(() => import('./CmsStickyHeaderLinksBlock'), {
      resolveComponent: (module) => module.CmsStickyHeaderLinksBlock,
    }),
  };
